import React from "react";

export default function DownloadApp() {
  return (
    <div className="d-flex flex-column flex-sm-column flex-1">
      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
      <a href="https://apps.apple.com/ca/app/vennchat/id6443394582">
        <div className="btn">苹果商店</div>
      </a>

      <a href="https://play.google.com/store/apps/details?id=ca.richlink.iment">
        <div className="btn">谷歌商店</div>
      </a>

      <div>
        <a href="https://vennchatandroidapk.s3.ap-east-1.amazonaws.com/Vennchat_5.13.1-playChina-release-5.13.1.apk">
          <div className="btn">中国手机（安卓）</div>
        </a>
        <a href="/notification-instructions">
          <p className="text-center text-white">打开推送设置说明</p>
        </a>
      </div>

      <a href="/chinese-apple-instructions">
        <div className="btn">中国手机（苹果）</div>
      </a>
    </div>
    <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center" >
          <a href="https://vennchatandroidapk.s3.ap-east-1.amazonaws.com/vennchat-desktop-mac-5.0.0.dmg">
            <div className="btn">Mac</div>
          </a>
          <a href="https://vennchatandroidapk.s3.ap-east-1.amazonaws.com/vennchat-desktop-win-5.0.0.exe">
            <div className="btn">Windows</div>
          </a>
        </div>
        


    </div>
    
  );
}
